<template>
  <div>
    <b-card>
      <b-row>
        <b-col />
        <b-col />
      </b-row>

      <div class="d-block text-center mt-3">
        <div class="main-div">
          <div class="d-flex justify-content-end">
            <vue-json-to-csv
              :json-data="tableRows"
              :labels="labelsForDownloadCsv"
              :csv-title="'Warehouse_csv_' + new Date().toISOString()"
              @success="() => handleCreatedCsvSuccess()"
              @error="val => handleDownloadCsvError(val)"
            >
              <b-button
                variant="primary"
                class="mr-2"
                :disabled="!tableRows.length"
              >
                {{ $t('downloadCsv') }}
              </b-button>
            </vue-json-to-csv>

            <b-button
              variant="primary"
              class="mb-3"
              @click="$refs.refInputEl.click()"
            >
              <input
                v-if="showInputFileTag"
                ref="refInputEl"
                type="file"
                class="d-none"
                accept=".xlsx,.xls,.csv"
                @input="handleCsvUploaded"
              />
              <span class="d-none d-sm-inline">
                {{ $t('importFile') }}
              </span>
            </b-button>
          </div>

          <b-row>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('productGroup') }}</label>
              <div>
                <treeselect
                  v-model="productGroupsSearch"
                  :multiple="true"
                  :options="productGroupsTreeData"
                  :no-children-text="'No categories'"
                  :placeholder="$t('productGroup')"
                  :max-height="100"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('productName') }}</label>
              <b-form-input
                id="productName"
                v-model="productNameSearch"
                name="productName"
                :placeholder="$t('productName')"
              />
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('tnVed') }}</label>
              <div>
                <treeselect
                  :key="tnVedTreeComponentKey"
                  ref="vueTnVedTreeSelect"
                  v-model="tnVedValues"
                  :multiple="true"
                  :async="isSearchMode"
                  :searchable="isSearchMode"
                  :options="tnVedTreeData"
                  :default-options="tnVedTreeData"
                  :load-options="loadOptions"
                  :auto-load-root-options="false"
                  :limit="0"
                  :cache-options="false"
                  :open-on-click="true"
                  :open-on-focus="true"
                  :placeholder="isSearchMode ? $t('enterTextOrCode') : $t('selectCategories')"
                  :delete-removes="false"
                  :backspace-removes="false"
                  :no-children-text="$t('noSubcategories')"
                  :no-options-text="$t('noCategories')"
                  :no-results-text="$t('noResults')"
                  :search-prompt-text="$t('enterTextOrCode')"
                  :loading-text="$t('loadingCategories')"
                  :limit-text="(count) => count > 1 ? $t('countCategoriesSelected', {count}) : $t('oneCategorySelected')"
                  class="tn-ved-treeselect"
                  @select="addSelectedOption"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('partNumber') }}</label>
              <b-form-input
                id="partNumber"
                v-model="partNumberSearch"
                name="partNumber"
                :placeholder="$t('partNumber')"
              />
            </b-col>
          </b-row>

          <!-- table -->
          <div class="table-container">
            <vue-good-table
              ref="vueGoodTableWarehouse"
              :columns="tableColumns"
              :rows="tableRows"
              :row-style-class="''"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true,
                selectAllByPage: false,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true,
                selectAllByGroup: true,
              }"
              :sort-options="{
                enabled: true,
              }"
              :pagination-options="{
                enabled: true,
                perPage: tableParams.currentPerPage,
                mode: 'records',
              }"
              style-class="vgt-table condensed"
              :is-loading="isItemsLoading"
              @on-page-change="handlePageChange"
              @on-per-page-change="handlePageChange"
              @on-sort-change="handleSortChange"
              @on-selected-rows-change="handleSelectionChanged"
            >
              <div slot="emptystate">
                <span>
                  {{
                    productGroupsSearch.length || productNameSearch.length
                      || partNumberSearch.length || tnVedValues.length ?
                        $t('noProductsBySearch') : $t('noProducts')
                  }}
                </span>
              </div>
              <template
                slot="table-column"
                slot-scope="props"
              >
                <div v-if="props.column.field !== 'favorite'">
                  <span>
                    {{ props.column.label }}
                  </span>
                </div>
                <div v-else>
                  <span>
                    <b-icon-star
                      v-if="favoriteFilterState === 1"
                      font-scale="0.85"
                      class="active-filter"
                      @click="favoriteFilterClick(props.row)"
                    />
                    <b-icon-star-fill
                      v-else
                      font-scale="0.85"
                      :class="{'disable-favorite': favoriteFilterState === 0, 'active-filter': favoriteFilterState === 2}"
                      @click="favoriteFilterClick()"
                    />
                  </span>
                </div>
              </template>

              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'group_id'">
                  {{ $store.state.products.groups.find(group => group.id === props.row.group_id) ? $store.state.products.groups.find(group => group.id === props.row.group_id).name : 'Default' }}
                </span>
                <span v-else-if="props.column.field === 'supplier'">
                  {{ props.formattedRow[props.column.field] }}
                </span>
                <div v-else-if="props.column.field === 'note'">
                  <div class="comments-div">
                    {{ props.row.note }}
                  </div>
                </div>
                <span v-else-if="props.column.field === 'delivery_type'">
                  {{ deliveryTypes[props.formattedRow[props.column.field].toLowerCase()] || props.formattedRow[props.column.field] }}
                </span>
                <div v-else-if="props.column.field === 'favorite'">
                  <div class="favorite-icon">
                    <b-icon-star-fill
                      v-if="props.row.favorite"
                      font-scale="0.85"
                      class="favorite-row-icon"
                      @click="favoriteClick(props.row)"
                    />
                    <b-icon-star
                      v-else
                      font-scale="0.85"
                      class="favorite-row-icon"
                      @click="favoriteClick(props.row)"
                    />
                  </div>
                </div>
                <span
                  v-else-if="props.column.field === 'actions'"
                  class=""
                >
                  <b-dropdown
                    variant="outline-primary"
                    size="sm"
                  >
                    <template #button-content>
                      <b-icon-plus-square
                        font-scale="0.95"
                        class="plus-icon"
                      />
                      <span class="mr-25 align-middle">
                        {{ $t('addToHotSale') }}
                      </span>
                    </template>

                    <b-dropdown-item
                      href="#"
                      @click="offerOfItemClick(null, props.row)"
                    >
                      <div>
                        <span class="create-hotsale-item">
                          + {{ $t('createHotSale') }}
                        </span>
                      </div>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-for="offer in offers"
                      :key="offer.id"
                      href="#"
                      @click="offerOfItemClick(offer.id, props.row)"
                    >
                      <div class="offer-content">
                        <div>
                          <span>#{{ offer.id }}: </span>
                        </div>
                        <div
                          v-for="(product, index) in offer.products"
                          :key="product.id"
                        >
                          <div class="product-name">
                            <span>{{ product.name }}</span>
                          </div>
                          <div class="product-count">
                            <span> ({{ product.count }})</span>
                            <span v-if="index !== offer.products.length - 1">
                              ,
                            </span>
                          </div>
                        </div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <template
                slot="pagination-bottom"
              >
                <div />
              </template>
            </vue-good-table>
          </div>

          <div class="d-flex flex-wrap justify-content-between">
            <div class="d-flex justify-content-start flex-wrap text-style">
              <div class="d-flex align-items-center">
                <div class="hot-sale-select-title">
                  <span>{{ $t('addSelectedProductsToHotSale') }}:</span>
                </div>
                <div class="hot-sale-select-div">
                  <b-form-select
                    v-model="hotSaleForProductsId"
                    class="ml-1 hot-sale-select"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="false"
                        disabled
                      >
                        <span>-- {{ $t('selectHotSale') }} --</span>
                      </b-form-select-option>
                    </template>

                    <b-form-select-option
                      :value="null"
                    >
                      <span>
                        <span>+ {{ $t('createHotSale') }}</span>
                      </span>
                    </b-form-select-option>

                    <b-form-select-option
                      v-for="offer in offers"
                      :key="offer.id"
                      :value="offer.id"
                    >
                      <div class="offer-content">
                        <div>
                          <span>#{{ offer.id }}: </span>
                        </div>
                        <div
                          v-for="(product, index) in offer.products"
                          :key="product.id"
                        >
                          <div class="product-name">
                            <span>{{ product.name }}</span>
                          </div>
                          <div class="product-count">
                            <span> ({{ product.count }})</span>
                            <span v-if="index !== offer.products.length - 1">
                              ,
                            </span>
                          </div>
                        </div>
                      </div>
                    </b-form-select-option>
                  </b-form-select>
                </div>
                <div class="align-items-center">
                  <b-button
                    variant="primary"
                    :disabled="(hotSaleForProductsId !== null && !hotSaleForProductsId) || !selectedRows.length"
                    @click="addItemsToHotsaleClick()"
                  >
                    {{ $t('add') }}
                  </b-button>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end flex-wrap text-style">
              <div class="d-flex align-items-center">
                <span class="d-flex">
                  {{ $t('itemsPerPage') }}:
                </span>
                <b-form-select
                  v-model="tableParams.currentPerPage"
                  :options="['10','25','50','100']"
                  class="mx-1 d-flex form-select"
                  @input="value => $refs.vueGoodTableWarehouse.perPageChanged({currentPerPage: value})"
                />
              </div>

              <div class="d-flex align-items-center ml-2">
                <span class="text-nowrap">
                  {{ tableParams.currentPerPage * (tableParams.currentPage - 1) + 1 }}
                  - {{ tableParams.currentPerPage * tableParams.currentPage >= tableParams.totalRows ? tableParams.totalRows : tableParams.currentPerPage * tableParams.currentPage }}
                  {{ $t('of') }} {{ tableParams.totalRows }} {{ $t('rows') }}
                </span>
              </div>

              <div class="d-flex align-items-center ml-3">
                <b-pagination
                  :value="1"
                  :total-rows="tableParams.totalRows"
                  :per-page="tableParams.currentPerPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1"
                  @input="value => $refs.vueGoodTableWarehouse.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div />
    </b-card>

    <import-csv
      v-if="modalVisible"
      :title="String($t('warehouse'))"
      :mode="'offer'"
      :import-mode="'itemsForWarehouse'"
      :excel-rows-full-received="excelRowsFull"
      @close-modal="closeImportFileModal"
      @update-products-all="updateProductsAll"
      @fetch-items="fetchItems"
    />

    <b-modal
      v-model="isShowTradingOffersEdit"
      size="xl"
      @hide="fetchOffers"
    >
      <trading-offers-new
        :is-new-mode-prop="!modalOfferId"
        :offer-id-prop="modalOfferId"
        :items-for-offer-prop="itemsForOfferProp"
        @close-offers-new="handleCloseOffersNew"
      />
    </b-modal>

    <b-modal
      ref="my-modal"
      :visible="tnVedModalVisible"
      :title="String($t('productsWillFilteredByCategories'))"
      centered
      hide-footer
      size="lg"
      @hide="tnVedModalVisible = false"
    >
      <div>
        <div>
          <div v-if="tnVedSelectedObjects.length">
            <div
              v-for="item in tnVedSelectedObjects"
              :key="item.id"
              class="selected-object-div"
            >
              <div class="selected-tn-ved-label">
                <div>
                  <div class="d-flex">
                    {{ item.label }}
                  </div>
                </div>
                <div class="remove-icon">
                  <feather-icon
                    icon="XIcon"
                    size="12"
                    @click="removeTnVedValue(item.id)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="mt-1">
              {{ $t('categoriesNotSelectedForFilter') }}
            </div>
            <br />
            <div class="mt-1 mb-1">
              {{ $t('selectCategoriesForFiltering') }}
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BRow,
  BPagination,
  BFormSelect,
  BFormSelectOption,
  BCard,
  BDropdownItem,
  BDropdown,
  BIconStarFill,
  BIconStar, BFormInput, BIconPlusSquare,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import Ripple from 'vue-ripple-directive';
import i18n from '@/libs/i18n';
import readXlsxFile from 'read-excel-file';
import 'vue-good-table/dist/vue-good-table.css';
import ImportCsv from '@/views/modals/ImportCsv.vue';
// import the component
// eslint-disable-next-line import/no-extraneous-dependencies
import Treeselect from '@riophae/vue-treeselect';
// import the styles
// eslint-disable-next-line import/no-extraneous-dependencies
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
// import "vue-select/dist/vue-select.css";
import store from '@/store';
import TradingOffersNew from '@/views/trading/trading-offers/TradingOffersNew.vue';
import VueJsonToCsv from 'vue-json-to-csv';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { findInTree } from '@/utils/tree';

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BButton,
    BPagination,
    BFormSelect,
    BFormSelectOption,
    VueGoodTable,
    BCard,
    ImportCsv,
    BIconStarFill,
    BIconStar,
    BIconPlusSquare,
    Treeselect,
    BDropdownItem,
    BDropdown,
    TradingOffersNew,
    VueJsonToCsv,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      default: (() => []),
    },
    addBtnDisabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'offer',
    },
    excelRowsFullReceived: {
      type: Array,
      default: (() => []),
    },
  },
  data: () => ({
    modalVisible: false,
    showInputFileTag: true,
    selectedCategory: null,
    isImportProductsLoading: false,
    selected: [],
    selectObjects: {},
    selectedRows: [],
    // excelRowsFull: [],
    tableParams: {
      currentPage: 1,
      currentPerPage: 10,
      totalRows: 0,
      totalPages: 1,
      sortBy: 'name',
      type: 'asc',
    },
    isTableContainsHeaders: true,
    searchTerm: '',
    partNumberSearch: '',
    productGroupsSearch: [],
    productGroupsTreeData: [
      {
        id: 1,
        label: 'DEFAULT',
        children: [],
      },
    ],
    tnVedTreeData: [],
    tnVedValues: [],
    productNameSearch: '',
    hotSaleForProductsId: false,
    tableRowsFull: [],
    excelRowsFull: [],
    isShowTradingOffersEdit: false,
    modalOfferId: null,
    itemsForOfferProp: [],
    isItemsLoading: false,
    favoriteFilterState: 0,
    filterParams: {},
    deliveryTypes: {
      retail: 'Retail',
      bulk: 'Bulk',
      oem: 'OEM',
    },
    isSearchMode: false,
    tnVedModalVisible: false,
    showTree: true,
    tnVedSelectedObjects: [],
    tnVedTreeComponentKey: 'tn-ved-treeselect_1',
  }),
  computed: {
    offers() {
      return store.state.offers.ownOffers.filter(offer => offer.can_edit);
    },
    tableRows() {
      let rows = [...this.tableRowsFull];

      if (this.productGroupsSearch.length) {
        rows = rows.filter(row => this.productGroupsSearch.some(productGroupId => row.group_id === productGroupId));
      }

      if (this.tnVedValues.length) {
        rows = rows.filter(row => this.tnVedValues.some(tnVedValue => String(row.tnv).startsWith(tnVedValue)));
      }

      if (this.productNameSearch.length) {
        rows = rows.filter(row => row.name.toLocaleLowerCase().includes(this.productNameSearch.toLocaleLowerCase()));
      }

      if (this.partNumberSearch.length) {
        rows = rows.filter(row => String(row.index).includes(this.partNumberSearch)); // by part number
      }

      return rows;
    },
    labelsForDownloadCsv() {
      const resObj = {};

      this.tableColumns.filter(column => !['actions', 'favorite'].includes(column.field)).forEach(column => {
        resObj[column.field] = { title: column.label };
      });

      return resObj;
    },
    isAdmin() {
      return this.$store.getters['profile/isAdmin'];
    },
    tableColumns() {
      const columns = this.mode === 'request' ? [
        {
          label: '',
          field: 'favorite',
          width: '30px',
          tdClass: 'favorite-td',
          sortable: false,
        },
        {
          field: 'group_id',
          label: i18n.t('productGroup'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'name',
          label: i18n.t('productName'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'index',
          label: i18n.t('partNumber'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'count',
          label: i18n.t('quantity'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'warranty',
          label: i18n.t('warranty'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'tnv',
          label: i18n.t('tnVed'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'delivery_type',
          label: i18n.t('deliveryType'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'note',
          label: i18n.t('comments'),
          width: '170px',
          sortable: true,
        },
      ] : [
        {
          label: '',
          field: 'favorite',
          width: '30px',
          tdClass: 'favorite-td',
          sortable: false,
        },
        {
          field: 'group_id',
          label: i18n.t('productGroup'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'name',
          label: i18n.t('productName'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'index',
          label: i18n.t('partNumber'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'tnv',
          label: i18n.t('tnVed'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'count',
          label: i18n.t('quantity'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'price',
          label: i18n.t('latestPrice'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'delivery_type',
          label: i18n.t('deliveryType'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'note',
          label: i18n.t('comments'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'actions',
          label: i18n.t('actions'),
          width: '180px',
          sortable: false,
        },
      ];

      if (this.isAdmin) {
        columns.splice(4, 0, {
          field: 'supplier',
          label: i18n.t('supplier'),
          width: '170px',
          sortable: true,
        });
      }

      return columns;
    },
  },
  watch: {
    tnVedValues: {
      deep: true,
      handler() {
        this.tnVedSelectedObjects = this.tnVedSelectedObjects.filter(tnVedObj => this.tnVedValues.includes(tnVedObj.id));

        const notPushedIds = this.tnVedValues.filter(id => !this.tnVedSelectedObjects.some(tnVedObj => tnVedObj.id === id));

        notPushedIds.forEach(id => {
          const notPushedObject = findInTree(this.tnVedTreeData, tnVedObj => tnVedObj.id === id);

          if (notPushedObject) {
            this.tnVedSelectedObjects.push(notPushedObject);
          }
        });
      },
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.initSelectedRows();
    });

    // TODO make promiseAll
    await this.$store.dispatch('products/fetch'); // fetch groups

    await this.fetchItems();

    await this.fetchOffers();

    this.addEyeIcon();
    this.addSearchIcon();

    this.tnVedTreeData = await this.fetchHsCodesTree();
  },
  methods: {
    rerenderTreeSelect() {
      const [str, number] = this.tnVedTreeComponentKey.split('_');

      this.tnVedTreeComponentKey = `${str}_${Number(number) + 1}`;
    },
    offerOfItemClick(offerId, row) {
      this.modalOfferId = offerId;

      const index = this.tableRowsFull.findIndex(tableRow => tableRow.index === row.index);

      this.itemsForOfferProp = [this.tableRowsFull[index]];

      this.$nextTick(() => {
        this.isShowTradingOffersEdit = true;
      });
    },
    addItemsToHotsaleClick() {
      this.modalOfferId = this.hotSaleForProductsId;

      this.itemsForOfferProp = JSON.parse(JSON.stringify(this.selectedRows));

      this.$nextTick(() => {
        this.isShowTradingOffersEdit = true;
      });
    },
    initSelectedRows() {
      this.$nextTick(() => {
        this.selectedRows = this.$refs.vueGoodTableWarehouse.selectedRows;
      });
    },
    async fetchItems() {
      this.isItemsLoading = true;

      await this.$http.get('/v1/items', {
        params: {
          page: this.tableParams.currentPage,
          per_page: this.tableParams.currentPerPage,
          ...this.filterParams,
          // sort_by: this.tableParams.sortBy,
          // sort_type: this.tableParams.sortType,
        },
      }).then(({ data }) => {
        this.tableRowsFull = data.data.map(item => ({
          id: item.id,
          group_id: 1,
          name: item.name,
          index: item.index,
          tnv: (item.tnv_codes && item.tnv_codes[0]?.code) || null,
          note: item.description,
          count: item.stock?.count,
          price: item.last_price?.price,
          favorite: item.favorite,
          delivery_type: item.package_type,
          supplier: item.created_by?.email || '-',
        }));

        this.$nextTick(() => {
          this.tableParams.totalRows = data.meta.total;
          this.tableParams.totalPages = Math.round(this.tableParams.totalRows / this.tableParams.currentPerPage);
        });
      }).finally(() => {
        this.isItemsLoading = false;
      });
    },
    async fetchOffers() {
      await store.dispatch('offers/fetchOffers', {
        fetchParam: this.isAdmin ? 'all' : 'own',
        data: {
          per_page: 100,
          page: 1,
        },
      });
    },
    async fetchHsCodesTree(action = 'LOAD_ROOT_OPTIONS', parentNode = null, searchQuery = null) {
      const paramOptions = {};

      if (action === 'ASYNC_SEARCH') {
        paramOptions.search = searchQuery;
        paramOptions.page = 1; // TODO remove after BE fix
        paramOptions.per_page = 1000; // TODO remove after BE fix
      } else if (action === 'LOAD_CHILDREN_OPTIONS') {
        paramOptions.parent = parentNode.id;
        paramOptions.page = 1; // TODO remove after BE fix
        paramOptions.per_page = 100; // TODO remove after BE fix
      } else { // action = LOAD_ROOT_OPTIONS
        paramOptions.level = 2;
        paramOptions.page = 1; // TODO remove after BE fix
        paramOptions.per_page = 97; // TODO remove after BE fix (after remove last record from response)
      }

      return this.$http.get('/v1/hscodes', {
        params: {
          ...paramOptions,
        },
      }).then(({ data }) => {
        const codeObjectsForTree = [];

        data.data.forEach(hsCodeObj => {
          const codeObjForTree = {};

          codeObjForTree.label = `${hsCodeObj.code} ${hsCodeObj.description}`;
          codeObjForTree.id = hsCodeObj.code;
          codeObjForTree.children = action === 'ASYNC_SEARCH' ? undefined : null; // temporary

          codeObjectsForTree.push(codeObjForTree);
        });

        if (['ASYNC_SEARCH', 'LOAD_ROOT_OPTIONS'].includes(action)) {
          return codeObjectsForTree;
        }

        if (action === 'LOAD_CHILDREN_OPTIONS') {
          const treeNode = findInTree(this.tnVedTreeData, codeObj => codeObj.id === parentNode.id);

          treeNode.children = codeObjectsForTree.length ? codeObjectsForTree : false;
        }

        return null;
      });
    },
    switchShowMode() {
      if (!this.isSearchMode) {
        this.isSearchMode = true;

        document.querySelectorAll('.tn-ved-treeselect .search-icon')[0].classList.remove('opacity');

        this.$nextTick(() => {
          document.querySelectorAll('.tn-ved-treeselect .vue-treeselect__input')[0].focus();
        });
      } else {
        this.isSearchMode = false;

        this.rerenderTreeSelect();

        this.$nextTick(() => {
          this.addEyeIcon();
          this.addSearchIcon();

          document.querySelectorAll('.tn-ved-treeselect .vue-treeselect__input-container')[0].focus();
        });
      }
    },
    async handleCsvUploaded() {
      // here - uploadDocuments()

      const xlsxFile = this.$refs.refInputEl.files ? this.$refs.refInputEl.files[0] : null;

      this.excelRowsFull = await readXlsxFile(xlsxFile);

      this.modalVisible = true;
    },
    preparingRowsData(arrayOfArrays) {
      return arrayOfArrays.map(array => {
        const obj = {};

        array.forEach((elem, index) => {
          obj[index] = elem ? String(elem) : elem;
        });

        return obj;
      });
    },
    preparingColumnsData(columnHeaders, maxArrayLength = 0) {
      const array = Array.isArray(columnHeaders) ? columnHeaders : Object.values(columnHeaders);

      if (array.length < maxArrayLength) {
        array.push(...Array(maxArrayLength - array.length).fill('-'));
      }

      return array.map((elem, index) => ({
        label: elem || '-',
        field: `${index}`,
        width: '170px',
        thClass: '',
        sortable: true,
      }));
    },
    async handlePageChange(params) {
      this.tableParams.currentPage = params.currentPage;
      this.tableParams.currentPerPage = params.currentPerPage;

      await this.fetchItems();
    },
    handleSelectionChanged({ selectedRows }) {
      this.selectedRows = selectedRows;
    },
    async handleSortChange(params) {
      this.tableParams.sortBy = params[0].field;
      this.tableParams.sortType = params[0].type;

      await this.fetchItems();
    },
    close() {
      this.$emit('close-modal');
    },
    async favoriteClick(row) {
      const initValue = this.tableRows[row.originalIndex].favorite;
      const index = this.tableRowsFull.findIndex(tableRow => tableRow.index === row.index);

      this.tableRowsFull[index].favorite = !this.tableRowsFull[index].favorite;

      const { data } = await this.$http.post(`/v1/items/favorites/${row.id}`);

      if (!data.status) {
        this.tableRows[row.originalIndex].favorite = initValue;
      }
    },
    updateProductsAll(updatedProducts) {
      this.tableRowsFull = [...this.tableRowsFull, ...updatedProducts.map(product => ({ ...product, group_id: 1 }))];

      this.$refs.vueGoodTableWarehouse.pageChanged({ currentPage: this.tableParams.currentPage });
    },
    handleCreatedCsvSuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('csvCreatedSuccessfully'),
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    },
    handleDownloadCsvError(err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('somethingWentWrong', { msg: err }),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      });
    },
    handleCloseOffersNew() {
      this.isShowTradingOffersEdit = false;
    },
    addSelectedOption(node) {
      this.tnVedSelectedObjects.push({
        id: node.id,
        label: node.label,
      });
    },
    removeTnVedValue(idToRemove) {
      const index = this.tnVedValues.findIndex(id => id === idToRemove);

      this.tnVedValues.splice(index, 1);
    },
    async loadOptions({
      action, parentNode, callback, searchQuery,
    }) {
      const options = await this.fetchHsCodesTree(action, parentNode, searchQuery);

      if (options) {
        callback(null, options);
      } else {
        callback();
      }
    },
    async favoriteFilterClick() {
      if (!this.favoriteFilterState) {
        this.favoriteFilterState = 1;
        this.filterParams.favorite = 0;
      } else if (this.favoriteFilterState === 1) {
        this.favoriteFilterState = 2;
        this.filterParams.favorite = 1;
      } else {
        this.favoriteFilterState = 0;
        delete this.filterParams.favorite;
      }

      await this.fetchItems();
    },
    closeImportFileModal() {
      this.modalVisible = false;

      this.showInputFileTag = false;

      this.$nextTick(() => {
        this.showInputFileTag = true;
      });
    },
    addEyeIcon() {
      const el = document.querySelectorAll('.tn-ved-treeselect .vue-treeselect__value-container')[0];

      const eyeIcon = document.createElement('div');

      eyeIcon.innerHTML = `
      <div class="tree-select-icon eye-icon" ">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8 3c-5 0-8 3-8 5s3 5 8 5 8-3 8-5-3-5-8-5zm0 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/>
          <path d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"/>
        </svg>
      </div>
    `;

      eyeIcon.addEventListener('click', () => {
        this.tnVedModalVisible = true;
      });

      el.appendChild(eyeIcon);
    },
    addSearchIcon() {
      const el = document.querySelectorAll('.tn-ved-treeselect .vue-treeselect__value-container')[0];

      const searchIcon = document.createElement('div');

      searchIcon.innerHTML = `
      <div class="tree-select-icon search-icon opacity">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"  viewBox="0 0 18 18" fill="#e8eaed">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59
              4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
          />
        </svg>
      </div>
    `;

      searchIcon.addEventListener('click', () => {
        this.switchShowMode();
      });

      el.appendChild(searchIcon);
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
.favorite-td {
  height: auto;
  align-content: center;
}

// vue-treeselect re-stylization
.vue-treeselect__menu-container {
  min-width: 200%;
}

.vue-treeselect__control {
  height: 2.714rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 1rem;
  color: #B9B9C3;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  .vue-treeselect__placeholder {
    color: #B9B9C3;
    top: 2px;
    padding-left: 9px;
  }

  .vue-treeselect__input-container {
    padding-left: 0;

    .vue-treeselect__input {
      padding-top: 5px;
      padding-left: 5px;
    }
  }
}

.tree-select-icon {
  width: 5%;
  float: right;
  position: absolute;
  cursor: pointer;
  color: #BEBEBE;
}

.tree-select-icon:hover {
  color: #7367F0;
  opacity: 1 !important;
}

.eye-icon {
  top: 0.5em;
  right: 5px;
}

.search-icon {
  top: 0.45em;
  right: 30px;
}

.opacity {
  opacity: 0.5 !important;
}
</style>

<style lang="scss" scoped>
@import 'vue-good-table/dist/vue-good-table';

.divider {
  margin: 0;
}

.card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.modal-class {
  background-color: #1ab7ea !important;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  padding-top: 50px !important;
}

.form-select {
  width: fit-content;
}

.text-style {
  color: #4e5154 !important;
}

.custom-class {
  background-color: #1ab7ea !important;
}

::v-deep .custom-th-class {
  background-color: #1ab7ea !important;
  text-align: center !important;
}

::v-deep .pointer {
  pointer-events: all !important;
  color: #1ab7ea !important;
}

::v-deep .custom-td-class {
  background-color: green !important;
}

::v-deep .vgt-checkbox-col {
  background-color: #1ab7ea !important;
}

::v-deep .column-select {
  position: absolute;
  top: -50px;
  width: 80%;
  z-index: 1000 !important;
}

.select-div {
  z-index: 1000 !important;
}

::v-deep .vgt-responsive {
  overflow-x: unset;
}

::v-deep .not-selected {
  color: #d0d0d0;
}

::v-deep .button-spinner {
  width: 14px;
  height: 14px;
}

.plus-icon {
  margin-right: 2px;
  padding-bottom: 2px;
}

.hot-sale-select-title {
  width: fit-content;
}

.hot-sale-select-div {
  width: 250px;
  margin-right: 40px;

  .hot-sale-select {
    width: 100%;
  }
}

.product-name {
  max-width: 200px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  align-content: center;
  margin-left: 5px;
}

.product-count {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  align-content: center;
}

.offer-content {
  max-width: 700px;
  text-overflow: ellipsis;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.disable-favorite {
  opacity: 0.3;
}

.active-filter {
  color: #7367F0;
  text-shadow: 0 0 1px #7367F0;
}

.favorite-row-icon {
  margin-left: 5px;
}

.favorite-icon {
  pointer-events: all;
  cursor: pointer;
  margin-bottom: 4px;
}

.comments-div {
  white-space: pre-line;
}

.create-hotsale-item {
  font-weight: bold !important;
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
  margin-left: 10px;
}

.selected-object-div {
  background-color: rgba(163, 157, 245, 0.7);
  color: black;
  margin: 15px 10px;
  padding: 10px;
  border-radius: 12px;
  font-size: 14px;
  width: fit-content;

  .remove-icon {
    display: flex;
    margin-left: 10px;
    color: #a90000;
    padding: 3px;
    cursor: pointer;
  }

  .remove-icon:hover {
    color: #ff0000;
  }
}

.tn-ved-treeselect {
  ::v-deep .vue-treeselect__limit-tip-text {
    padding-left: 9px !important;
    color: #7367F0;
    font-size: 1rem;
  }
}

.selected-tn-ved-label {
  display: flex;
  justify-content: space-between;
}
</style>
