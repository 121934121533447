export function findInTree(tree, callback, findOne = true, childrenKey = 'children', firstCall = true) {
  const result = [];

  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    if (callback(node)) {
      result.push(node);
    } else if (node[childrenKey]) {
      const nodesResult = findInTree(node[childrenKey], callback, findOne, childrenKey, false);

      if (nodesResult.length) {
        result.push(...nodesResult);
      }
    }

    if (findOne && result.length) {
      break;
    }
  }

  return findOne && firstCall ? result[0] : result;
}
